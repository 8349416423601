p {
    font-size: 1.1rem;
}

/* ////////////////////////////////////////// CLASS STYLES //////////////////////////////////////////// */

.text-break {
    margin-bottom: 5px;
}

.para-break {
    margin-bottom: 10px;
}

.text-subtitle {
    text-align: center;
    padding: 5px;
    margin: 0;
    font-size: 1.2rem;
}

.page-title {
    font-weight: 700;
    font-family: "Amatic SC", cursive;
    font-size: 3.7rem;
    text-align: center;
    margin: 0 auto;
    padding: 10px 0;
    width: 90%;
}