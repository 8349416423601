/* *********************** SpecificPost-Section ************************* */

main {
    background-color: #ccedd2;
    padding: 50px 0;
    flex-grow: 1;
}

.specific-post {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    width: 75%;
    padding: 20px;
    margin: 0 auto;
    border: 2px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
}

.specificPost-img {
    border: 2px solid dimgray;
    border-radius: 8px;
    margin: 40px auto;
    width: 300px;
    height: 300px;
    display:block;
    box-shadow: 10px 5px 5px dimgray;
    object-fit: cover;
}

.specificPost-description {
    display:inline-block;
    margin: 0 50px;
}

.specificPost-content {
    font-size: 1rem;
    text-align: justify;
    line-height: 170%;
    padding: 20px 40px;
    margin:  0 auto;
    width: 85%;
}

.specificPost-content::first-letter {
    font-size: 130%;
}

.category-folder {
    text-align: right;
    padding: 5px 40px;
}

.category-icon{
    padding-left: 10px;
}

.category-link {
    color: #06623b;
}

.dividing-line {
    display: flex;
    margin: 10px auto;
    height: 20%;
    width: 20%;
}

/* *********************** Comment-Section ************************* */

.section-title {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 2.8rem;
    text-align: center;
    padding: 20px 0 30px;
}

.comments {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    border: 2px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
    width: 75%;
    margin: 20px auto 30px;
    padding: 20px;
}

.comment {
    border: 1px solid dimgray;
    border-radius: 3px;
    box-shadow: 2px 2px 2px dimgray;
    margin: 10px auto;
    padding: 5px;
    width: 90%;
}

.date {
    text-align: left;
    padding: 10px 30px 5px;
    margin: 0;
    font-style: italic;
}

.comment-content {
    font-family: "Segoe UI","Roboto","Helvetica Neue","Arial","Noto Sans",sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: justify;
    padding: 10px 30px;
    width: 85%;
    margin: 5px auto;
}

pre {
    white-space: pre-wrap;
}

.comment-author {

    text-align: right;
    font-style: italic;
    padding: 5px 30px 10px;
    margin: 0;
}

/* ******************** */

label {
    padding-top: 5px;
}

.input-form {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    border: 2px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
    width: 75%;
    margin: 30px auto;
    padding: 30px 20px;
}

.commentForm-title {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 2.8rem;
    text-align: center;
    margin: 0;
    padding: 20px 0 10px;
}

.subtitle {
    text-align: center;
    font-size: 1.2rem;
}

.form {
    /* border: 1px solid dimgray;
    border-radius: 3px;
    box-shadow: 2px 2px 2px dimgray; */
    margin: 10px auto;
    padding: 30px;
    width: 90%;
}

.button {
    text-align: right;
    background-color: mediumseagreen;
    border: 1px solid #06623b;
    box-shadow: 2px 2px 2px #06623b;
}

.errorMessage {
    color: red;
}