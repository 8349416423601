
.home{
    background-color: #ccedd2;
    padding: 30px 0;
    flex-grow: 1;
}

.welcome {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    padding: 30px 20px 40px;
    width: 95%;
    margin: 0 auto 30px;
    border: 1.5px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
}

.sidebar {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    margin-top: 20px;
    padding: 0 20px 5px;
}

.sidebar-title {
    font-family: "Amatic SC", cursive;
    font-size: 2.1rem;
    padding: 5px 20px;
}

.sidebar-link {
    color: #07ac67;
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    padding: 7px 5px;
}

.folder-icon{
    padding-right: 10px;
}

.home-button {
    margin-top: 0;
    text-align: left;
    background-color: rgb(96, 201, 143);
    border: 1px solid #06623b;
    box-shadow: 2px 2px 2px #06623b;
}

.welcome-title {
    font-weight: 700;
    font-family: "Amatic SC", cursive;
    font-size: 3.7rem;
    text-align: center;
    padding: 10px 0;
    margin: 0 auto;
}

.welcome-text {
    text-align: justify;
    line-height: 1.7;
    padding: 5px 30px 5px 50px;
}

.home-subtitle {
    text-align: center;
    padding: 5px;
    margin: 0;
    font-size: 1.2rem;
}

/* ************************** POST SECTION ************************* */

.post {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    width: 90%;
    padding: 20px;
    margin: 30px auto;
    border: 1px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
}

.post-img {
    border: 2px solid dimgray;
    border-radius: 8px;
    margin: 20px 40px;
    width: 150px;
    height: 150px;
    display:inline-block;
    vertical-align: middle;
    box-shadow: 2px 2px 2px dimgray;
    object-fit: cover;
}

.post-description {
    display:inline-block;
    margin: 0 50px;
    vertical-align: middle;
}

.post-title {
    font-weight: 700;
    font-family: "Amatic SC", cursive;
    font-size: 2.7rem;
}

.post-content {
    font-size: 16px;
}

.post-link {
    display: inline-block;
    text-align: left;
    width: 50%;
}

.post-category {
    display: inline-block;
    text-align: right;
    width: 50%;
}

.post-category-link {
    color: #07ac67;
    padding: 0 5px 5px;
    display: inline-block;
    text-align: right;
}

.post-folder-icon {
    color: #1d9764;
    padding-right: 5px;
}

/* ************************** GOODBYE SECTION ************************* */

.goodbye {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    padding: 20px 0 30px;
    width: 95%;
    margin: 20px auto;
    border: 1.5px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
}

.goodbye-title {
    width: 80%;
    margin: 0 auto;
    font-weight: 700;
    font-family: "Amatic SC", cursive;
    font-size: 3.2rem;
    text-align: center;
    padding: 10px 0;
}

.goodbye-text{
    width: 80%;
    margin: 0 auto;
    text-align: justify;
    line-height: 1.7;
    padding: 5px 30px;
}


/* 
possible color themes for the site:

rosé/pastellrosa
    page background-color: #ddb6c6;
    post background-color: #f1d4d4;

lila/pastellviolett
    page background-color: #c9b6e4;
        alternativ, sanfterer übergang: #e1ccec;
    post background-color: #f0e3ff;

orange/pastellgelb
    page background-color: #fed39f;
    post background-color: #f6eec9;

grün/mintgreen
    page background-color: #ccedd2;
    post background-color: #edffea
    post box-shadow: mediumseagreen;
    post-img border color: #06623b;

*/