.about {
    background-color: #ccedd2;
    padding: 20px 0;
    line-height: 1.7;
    flex-grow: 1;
}

.box {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    width: 80%;
    padding: 20px;
    margin: 30px auto;
    border: 1px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
}

.dividing-line {
    display: flex;
    margin: 10px auto;
    height: 20%;
    width: 20%;
}

.about-break {
    margin-bottom: 5px;
}

.hidden-button {
    color: white;
    text-align: right;
    background-color: mediumseagreen;
    border: 1px solid #06623b;
    box-shadow: 2px 2px 2px #06623b;
}

/* ****************************************** */

.about-info-box {
    text-align: center;
}

.about-img{
    width: 50%;
    height: 50%;
    border: 2px solid dimgrey;
    border-radius: 8px;
    margin: 25px auto;
    text-align: center;
    vertical-align: middle;
    object-fit: cover;
}

.cuddledragon-info {
    text-align: justify;
    width: 80%;
    margin: 10px auto;
    padding: 10px 30px 25px;
}


/* **********************  ABOUT INFO BOX - COMPONENT ************************ */

.detailed-info {
    text-align: justify;
    vertical-align: middle;
}

.info-title {
    font-family: "Amatic SC", cursive;
    font-size: 2.8rem;
    padding: 5px;
    width: 95%;
    margin: 0 auto;
}

.info-p {
    padding: 5px;
    width: 95%;
    margin: 0 auto;
}

.info-img {
    padding: 0;
    width: 400px;
    height: 300px;
    border: 2px solid dimgray;
    border-radius: 5px;
    vertical-align: middle;
    margin: auto auto;
    object-fit: cover;
}

/* **********************  HIDDEN INFO BOX - COMPONENT ************************ */

.hidden-info {
    text-align: center;
}

.hidden-title {
    font-family: "Amatic SC", cursive;
    font-size: 3.3rem;
    text-align: center;
    margin: 20px auto;
}

.hidden-img {
    height: 300px;
    width: 400px;
    border: 2px solid dimgrey;
    border-radius: 8px;
    margin: 25px auto;
    text-align: center;
    vertical-align: middle;
    object-fit: cover;
}

.hidden-info-p {
    text-align: justify;
    vertical-align: middle;
    padding: 5px;
    width: 80%;
    margin: 0 auto;
}

.hidden-subtitle {
    font-size: 1.1rem;
    padding-bottom: 10px;
}

.hidden-info-explication {
    font-size: 0.9rem;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.about-link {
    color: #07ac67;
}