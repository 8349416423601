.unsubscribe-main {
    background-color: #ccedd2;
    padding: 20px 0;
    line-height: 1.7;
    flex-grow: 1;
}

.unsubscribe-box {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    width: 70%;
    padding: 30px 20px;
    margin: 30px auto;
    border: 2px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
    text-align: center;
}

.unsubscribe-title {
    font-family: "Amatic SC", cursive;
    font-size: 2.6rem;
    text-align: center;
    margin: 10px 20px;
    padding: 10px 10px 20px;
}

.unsubscribeForm-title {
    font-family: "Amatic SC", cursive;
    text-align: center;
    font-weight: 700;
    padding: 5px 0 20px;
    font-size: 1.9rem;
}