.main-404 {
    background-color: #ccedd2;
    padding: 20px 20px 30px;
    text-align: center;
    flex-grow: 1;
}

.error-box {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    width: 80%;
    padding: 20px;
    margin: 30px auto;
    border: 1px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
}

.error-img {
    border: 2px solid dimgray;
    border-radius: 8px;
    margin: 20px 40px;
    width: 300px;
    height: 300px;
    vertical-align: middle;
    box-shadow: 2px 2px 2px dimgray;
}

.error-message {
    font-weight: 700;
    font-family: "Amatic SC", cursive;
    font-size: 3.3rem;
    text-align: center;
    padding: 10px 0;
    width: 70%;
    margin: 0 auto 20px;
}

.error-p {
    text-align: justify;
    line-height: 1.7;
    padding: 5px 20px;
    width: 80%;
    margin: 0 auto;
}

.error-button {
    text-align: center;
    background-color: rgb(96, 201, 143);
    border: 1px solid #06623b;
    box-shadow: 2px 2px 2px #06623b;
    margin: 50px 0;
}

.error-link {
    color: #045c37;
}