.impressum-main {
    background-color: #ccedd2;
    padding: 20px 0;
    line-height: 1.7;
    flex-grow: 1;
}

.box {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    width: 80%;
    padding: 20px;
    margin: 30px auto;
    border: 1px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
}

.impressum-link {
    display: inline-block;
    text-align: center;
    color: #07ac67;
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    padding: 0 5px;
    margin: 0 15px;
}

.impressum-link:hover {
    color: #06623b;
}

.version-link {
    display: inline;
    text-align: center;
    margin-top: 10px;
}

.impressum-links-container {
    text-align: center;
    margin: 13px 15px;
}

/* //////////////////////////////////  BEREICH IMPRESSUM /////////////////////////////////////// */

.impressum {
    margin: 30px auto;
    padding: 20px;
    border: 1px solid #06623b;
    border-radius: 5px;
    box-shadow: 5px 2.5px 2.5px mediumseagreen;
    width: 85%;
}

.impressum-title {
    font-weight: bold;
    font-size: 1.9rem;
    text-align: center;
}

.impressum-content {
    text-align: justify;
    padding: 10px 20px 10px 10%;
    margin: 0 auto;
}

/* //////////////////////////////  BEREICH DATENSCHUTZERKLÄRUNG //////////////////////////////////// */

.datenschutzerklaerung {
    margin: 30px auto;
    padding: 20px;
    border: 1px solid #06623b;
    border-radius: 5px;
    box-shadow: 5px 2.5px 2.5px mediumseagreen;
    width: 90%;
}

.datenschutzerklaerung-content {
    text-align: justify;
    padding: 10px 20px;
    margin: 0 auto;
}

.datenschutz-subtitle {
    font-weight: bold;
    font-size: 1.3rem;
    margin: 20px 0;
}

.datenschutz-point {
    padding-left: 20px;
}