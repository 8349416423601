
.compose-main {
    background-color: #fbcffc;
    padding-bottom: 30px;
    flex-grow: 1;
}

.compose-title {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 2.8rem;
    text-align: center;
    padding: 20px 0 30px;
}

.compose-form {
    background-color: #f0e3ff;
    /* background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png"); */
    border: 2px solid #c97bf0;
    border-radius: 5px;
    box-shadow: 10px 5px 5px #975fb3;
    width: 90%;
    margin: 10px auto 0;
    padding: 30px 20px;
}

.img-preview {
    max-width: 500px;
    max-height: 400px;
}

.button-compose {
    background-color: #d89cf6;
    box-shadow: 2px 2px 2px darkorchid;
}