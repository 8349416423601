.footer {
    background-color: #f8f9fa;
    background-image: url("https://www.transparenttextures.com/patterns/brushed-alum.png");
    text-align: center;
    padding-top: 20px;
    margin: 0;
    border-top: 1px solid lightgray;
}

.footer-message {
    font-family: "Amatic SC", cursive;
    font-size: 2em;
}

.copyright {
    margin: 0;
    padding-bottom: 10px;
    padding-top: 0;
}

.footer-link {
    color: seagreen;
    display: inline-block;
    margin: 3px 7px;
}

.footer-link :hover {
    text-decoration-line: underline;
}