.newsletter-main {
    background-color: #ccedd2;
    padding: 20px 0;
    line-height: 1.7;
    flex-grow: 1;
}

.signUp-form {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    width: 75%;
    padding: 30px 20px;
    margin: 30px auto;
    border: 1px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
    text-align: center;
}
.newsletter-title {
    font-family: "Amatic SC", cursive;
    font-size: 3.3rem;
    text-align: center;
    margin: 10px auto;
    padding: 10px;
}

.newsletter-subtitle {
    text-align: center;
    font-weight: 700;
    padding: 5px 5px 0;
    font-size: 1.4rem;
}

.newsletter-p {
    text-align: justify;
    padding: 0 10%;
    /* margin-bottom: .5rem; */
    margin: auto auto .5rem;
    width: 90%;
}

.form {
    border: 1px solid dimgray;
    border-radius: 8px;
    box-shadow: 2px 2px 2px dimgray;
    margin: 20px auto;
    padding: 35px 45px;
    width: 80%;
    text-align: left;
    vertical-align: middle;
}

.form-button {
    font-size: 1.1rem;
    padding-top: 5px;
    margin-top: 15px;
}

.signupForm-title {
    font-family: "Amatic SC", cursive;
    text-align: center;
    font-weight: 700;
    padding: 5px 5px 20px;
    font-size: 2.6rem;
}