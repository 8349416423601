.category {
    background-color: #ccedd2;
    padding: 20px 0 20px;
    line-height: 1.7;
    flex-grow: 1;
}

.category-header {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    padding: 5px 20px 15px;
    width: 95%;
    margin: 10px auto 40px;
    border: 1.5px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
}

.category-title {
    font-family: "Amatic SC", cursive;
    font-size: 3rem;
    padding: 0 20px;
    text-align: center;
}

.category-name {
    font-family: 'Amatic SC', cursive;
    font-size: 3.7rem;
    font-weight: bold;
    padding: 0, 10px;
}

.category-text {
    text-align: justify;
    line-height: 1.7;
    padding: 5px 30px 5px 50px;
    width: 80%;
    margin: 20px auto;
}

.notFound-box{
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    padding: 20px;
    width: 90%;
    margin: 20px auto 40px;
    border: 1px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
    text-align: center;
}