.popUp-content {
    position: relative;
    top: 30%; /* 50% from the top */
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    width: 40%;
    padding: 20px 10px;
    margin: 0 auto;
    z-index: 5;
    border: 3px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px rgb(91, 114, 102);
    text-align: center;
}

.popUp-overlay {
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Black fallback color */
    overflow-x: hidden; /* Disable horizontal scroll */
}

.close-popUp-btn {
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding-left: 80%;
    color: #06623b;
}

.popUp-link {
    color: #06623b;
}