.login {
    background-color: #ccedd2;
    padding: 20px 0;
    line-height: 1.7;
    flex-grow: 1;
}

.login-box {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    width: 50%;
    padding: 20px;
    margin: 30px auto;
    border: 1px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
    text-align: center;
}