.contact {
    background-color: #ccedd2;
    padding: 20px 0;
    line-height: 1.7;
    flex-grow: 1;
}

.contact-box {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    width: 80%;
    padding: 20px;
    margin: 30px auto;
    border: 1px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
    text-align: center;
}

.contact-img {
    width: 300px;
    height: 300px;
    text-align: center;
    padding: 10px;
}

.contact-subtitle {
    text-align: center;
    padding: 5px;
    margin: 20px 0;
    font-size: 1.2rem;
}

.contact-p {
    text-align: justify;
    padding-left: 20px;
    margin-bottom: .5rem;
}

.subject-list {
    border: 1px solid dimgray;
    border-radius: 8px;
    margin: 20px 40px;
    padding: 20px 40px;
    display:inline-block;
    vertical-align: middle;
    box-shadow: 2px 2px 2px dimgray;
}

/* ********************* CONTACT FORM ************************** */

.contactForm-title {
    font-family: "Amatic SC", cursive;
    font-size: 2.7rem;
    text-align: center;
    padding-bottom: 10px;
}

.contactForm-p {
    text-align: center;
    padding: 5px;
    margin: 10px 0;
    font-size: 1.2rem;
}

.form {
    margin: 10px auto;
    padding: 30px;
    width: 90%;
    text-align: left;
}