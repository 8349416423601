.navbar {
    background-image: url("https://www.transparenttextures.com/patterns/brushed-alum.png");
    border-bottom: 0.1px solid lightgrey;
}

.navbar-brand {
    margin-left: auto 20px;
    font-weight: 700;
    font-family: "Amatic SC", cursive;
    font-size: 3rem;
    text-decoration: none;
    float: left;
}

.brand-logo {
    margin: auto 20px;
    border-radius: 3px;
    border: 0.2px solid lightgrey;
}

.nav-item {
    font-size: 1.1rem;
    margin-right: 20px;
}

/* screen-width depending styles
/* -> get the logo-img to be above the Blog title "Cuddledragons Secret Lair" */
  
  /* If the screen size is 600px or less, set these styles */
  /* @media only screen and (max-width: 576px) { */
    
  /********** Large devices only **********/
@media (min-width: 1200px) {
    /* makes title appear in one line and hides the two-liner on large screen */
    #logo-title{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        clear: both; 
    }
    #logo-title-xs{
        position: absolute;
        visibility: hidden;
        float: left;
    }
}

/********** Medium devices only **********/
@media (min-width: 992px) and (max-width: 1199px) {
    .nav-link{
        text-align: center;
    }
    /* makes title appear in one line and hides the two-liner on medium screen */
    #logo-title{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        clear: both; 
    }
    #logo-title-xs{
        position: absolute;
        visibility: hidden;
        float: left;
    }
}

/********** Small devices only **********/
@media (min-width: 768px) and (max-width: 991px) {
    #logo-img {
    }
    #logo-title{
        text-align: center;
    }
    .nav-link{
        text-align: center;
    }
     /* makes title appear in one line and hides the two-liner on small screen */
    #logo-title{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        clear: both; 
    }
    #logo-title-xs{
        position: absolute;
        visibility: hidden;
        float: left;
    }
}

/********** Extra small devices only **********/
@media (max-width: 767px) {
    .brand-logo {
        position: absolute;
        visibility: hidden;
        float: left;
    }
    .navbar-brand {
        text-align: center;
        word-wrap: normal;
    }
    .navbar-toggler {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .navbar {
        background-color: rebeccapurple;
    }
    .nav-link{
        text-align: center;
    }
    .nav-item{
        margin-right: 0;
    }
    .button:focus {
        outline: none;
    }
    /* makes title appear in two lines and hides the one-liner on minimum screen */
    #logo-title{
        position: absolute;
        visibility: hidden;
        float: left;
    }
    #logo-title-xs{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        clear: both; 
    }
}