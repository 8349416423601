.categories {
    background-color: #ccedd2;
    padding: 30px 0 20px;
    line-height: 1.7;
    flex-grow: 1;
}

.categories-title {
    font-weight: 700;
    font-family: "Amatic SC", cursive;
    font-size: 3.7rem;
    text-align: center;
    padding: 0;
}

.categories-subtitle {
    text-align: center;
    padding: 5px;
    margin: 0;
    font-size: 1.2rem;
}

.categories-description {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    padding: 30px 20px 40px;
    width: 95%;
    margin: 0 auto 30px;
    border: 1.5px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
}

.categories-text {
    text-align: justify;
    line-height: 1.7;
    padding: 5px 20px;
    margin: 0 auto;
    width: 85%;
}

.categories-row {
    width: 80%;
    margin: 0 auto;
}

/* ************************** SPECIFIC CATEGORY COMPONENT SECTION ************************* */

.specific-category {
    background-color: #edffea;
    background-image: url("https://www.transparenttextures.com/patterns/cross-scratches.png");
    width: 90%;
    padding: 20px 30px;
    margin: 20px auto;
    border: 1px solid #06623b;
    border-radius: 5px;
    box-shadow: 10px 5px 5px mediumseagreen;
    text-align: center;
}

.specific-category-img {
    border: 2px solid dimgray;
    border-radius: 8px;
    margin: 20px auto;
    width: 200px;
    height: 200px;
    box-shadow: 2px 2px 2px dimgray;
    object-fit: cover;
}

.specific-category-description { 
    margin: 5px 20px;
}

.specific-category-title {
    font-weight: 700;
    font-family: "Amatic SC", cursive;
    font-size: 2.7rem;
    padding-top: 5px;
}

.specific-category-content {
    font-size: 16px;
    text-align: justify;
}

.categories-link {
    color: #07ac67;
    padding: 5px;
    text-align: right;
}